const usersData = [
  { modulo: 'Samppa Nori',orden:1 ,ultimo_usuario:'ldalvarado', nivel: 1, status: true},
  { modulo: 'Estavan Lykos',orden:2 ,ultimo_usuario:'katy', nivel: 1, status: true},
  { modulo: 'Chetan Mohamed',orden:3 ,ultimo_usuario:'ldalvarado', nivel: 2, status: true},
  { modulo: 'Derick Maximinus',orden:4 ,ultimo_usuario:'ldalvarado', nivel: 1, status: true},
  { modulo: 'Friderik Dávid',orden:5,ultimo_usuario:'katy', nivel: 1, status: true},
  { modulo: 'Yiorgos Avraamu',orden:6,ultimo_usuario:'katy', nivel: 2, status: false, _classes: 'table-danger'},
]

export default usersData


